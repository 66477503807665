import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
// 首页
const Home = lazy(() => import('pages/Home'))
// 布局
const DashboardLayout = lazy(() => import('layouts/DashboardLayout'))
const DashboardNoSiderLayout = lazy(() =>
  import('layouts/DashboardNoSiderLayout')
)
// 登录检查
const LoginRequired = lazy(() => import('pages/LoginRequired'))
// 登录/退出
const Login = lazy(() => import('pages/Login'))
const Logout = lazy(() => import('pages/Logout'))
// 课程创建
const CourseManage = lazy(() => import('apps/course/CourseManage'))
const CourseCreateUpdate = lazy(() => import('apps/course/CourseCreateUpdate'))
const CourseCategoryManage = lazy(() =>
  import('apps/course/CourseCategoryManage')
)
const CourseList = lazy(() => import('apps/course/CourseList'))
// 现场教学点创建
const TeachsiteManage = lazy(() => import('apps/teachsite/TeachsiteManage'))
const TeachsiteCreateUpdate = lazy(() =>
  import('apps/teachsite/TeachsiteCreateUpdate')
)
const TeachSiteList = lazy(() => import('apps/teachsite/TeachSiteList'))
// 项目创建
const ProjectList = lazy(() => import('apps/project/ProjectList'))
// 用户项目
const UserProjectList = lazy(() => import('apps/project/UserProjectList'))
// 人工智能聊天记录
const AllchatsList = lazy(() => import('apps/chat/AllchatsList'))
// 用户协议
const UserAgreement = lazy(() => import('apps/user/UserAgreement'))
// 隐私协议
const PrivacyAgreement = lazy(() => import('apps/user/PrivacyAgreement'))
// 工作台
const WorkingTable = lazy(() => import('apps/user/WorkingTable'))

const ProjectCreateStep = lazy(() => import('apps/project/ProjectCreateStep'))
const ProjectCreateSuccess = lazy(() =>
  import('apps/project/ProjectCreateSuccess')
)

const CustomCategory = lazy(() =>
  import('apps/project/components/CustomCategory')
)
// 个人信息
const UserProfile = lazy(() => import('apps/user/UserProfile'))
// 用户管理
const UserManage = lazy(() => import('apps/user/UserManage'))
// 系统参数
const Systemparameters = lazy(() =>
  import('apps/systemparameters/Systemparameters')
)
// Not found
const NotFound = lazy(() => import('pages/NotFound'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/"
        element={
          <LoginRequired>
            <DashboardLayout />
          </LoginRequired>
        }
      >
        <Route path="user/profile" element={<UserProfile />} />
        <Route path="user/usermanage" element={<UserManage />} />
        <Route path="system/systemparameters" element={<Systemparameters />} />
        <Route path="course/course">
          <Route path="" element={<CourseManage />} />
          <Route path="create" element={<CourseCreateUpdate />} />
          <Route path=":id/edit" element={<CourseCreateUpdate />} />
        </Route>
        <Route path="teachsite/teachsite">
          <Route path="" element={<TeachsiteManage />} />
          <Route path="create" element={<TeachsiteCreateUpdate />} />
          <Route path=":id/edit" element={<TeachsiteCreateUpdate />} />
        </Route>
        <Route path="project/project">
          <Route path="" element={<ProjectList />} />
        </Route>

        {/* 工作台 */}
        <Route path="user/WorkingTable">
          <Route path="" element={<WorkingTable />} />
        </Route>
        {/* 人工智能聊天记录 */}
        <Route path="chat/allchats">
          <Route path="" element={<AllchatsList />} />
        </Route>
        {/* 用户项目列表 */}
        <Route path="user/project">
          <Route path="" element={<UserProjectList />} />
        </Route>
        {/* 用户课程 */}
        <Route path="user/course">
          <Route path="" element={<CourseList />} />
        </Route>
        {/* 用户教学点 */}
        <Route path="user/teachsite">
          <Route path="" element={<TeachSiteList />} />
        </Route>
        {/* 课程类别管理 */}
        <Route
          path="teach/coursecategorymanage"
          element={<CourseCategoryManage />}
        />
        {/* 培训对象类别管理 */}
        <Route path="settings/categorymanage" element={<CustomCategory />} />
      </Route>

      <Route
        path="/project/project/"
        element={
          <LoginRequired>
            <DashboardNoSiderLayout />
          </LoginRequired>
        }
      >
        <Route path="create" element={<ProjectCreateStep />} />
        <Route path="success" element={<ProjectCreateSuccess />} />
      </Route>
      {/* 用户协议 */}
      <Route path="user/UserAgreement" element={<UserAgreement />} />
      {/* 隐私协议 */}
      <Route path="user/PrivacyAgreement" element={<PrivacyAgreement />} />
      {/* 登录 */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Login />} />
      {/* 退出 */}
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
