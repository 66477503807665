import { createSlice } from '@reduxjs/toolkit'
import TokenService from 'services/token'

const user = TokenService.getUser()

const initialState = user
  ? {
      isLogin: true,
      user,
      system: {}
    }
  : {
      isLogin: false,
      user: null,
      system: {}
    }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload
      state.isLogin = true
    },
    update: (state, action) => {
      state.user.user = action.payload
      TokenService.updateUser(action.payload)
    },
    logout: (state) => {
      state.user = null
      state.isLogin = false
    },
    setSystem: (state, action) => {
      state.system = action.payload
    }
  }
})

export const { login, update, logout, setWebsite, setSystem } =
  authSlice.actions
export default authSlice.reducer
