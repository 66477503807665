const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  return user?.refresh_token
}

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  return user?.access_token
}

const updateLocalAccessToken = (token) => {
  const user = JSON.parse(localStorage.getItem('user'))
  user.access_token = token
  localStorage.setItem('user', JSON.stringify(user))
}

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'))
}

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

const updateUser = (data) => {
  const user = JSON.parse(localStorage.getItem('user'))
  user.user = data
  localStorage.setItem('user', JSON.stringify(user))
}

const removeUser = () => {
  localStorage.removeItem('user')
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  updateUser,
  removeUser
}

export default TokenService
