import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(<App />)

ReactDOM.render(
  <ConfigProvider
    // 更改全局主题色
    theme={{
      token: { colorPrimary: '#2f54eb' }
    }}
  >
    <App />
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
