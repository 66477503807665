import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider, Spin } from 'antd'
import zh from 'antd/locale/zh_CN'
import 'antd/dist/reset.css'
import store from 'store'
import AppRoutes from 'urls'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                flexGrow: '1',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh'
              }}
            >
              <Spin size="large" />
            </div>
          }
        >
          <ConfigProvider locale={zh}>
            <AppRoutes />
          </ConfigProvider>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
